<div *ngIf="styleConnected !== false; else elseStyleConnected">
  <div *ngIf="!isError; else elseAuthenticate" id="layout-wrapper" aria-live="polite">
    <div class="container-fluid p-0">
<!--    <div class="container-fluid p-0" [ngClass]="{'pixel': (eProfile$ | async)?.business === 2}">-->
      <app-sidebar-menu #sidebarMenu></app-sidebar-menu>
      <div class="d-flex flex-column flex-lg-row">
        <ng-container *ngTemplateOutlet="sidebarMenu.header"></ng-container>
        <ng-container *ngTemplateOutlet="sidebarMenu.menu"></ng-container>

        <div class="content w-100 h-100 order-lg-1 order-1" style="min-height: 100vh;">
          <ng-container *ngIf="subscription$ | async as subscription">
            <ng-container *ngIf="willExpire(subscription)">
              <div class="bg-primary p-4 d-flex gap-2 justify-content-center color-tertiary fw-bold" style="height: 64px">
                <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit</div>
                <a class="text-decoration-underline" style="color: var(--color-black-3)" [routerLink]="['/profile/plan']">Bouton</a>
              </div>
            </ng-container>
          </ng-container>
          <div id="global-sticky" #globalSticky class="sticky-top container-fluid">
          </div>
          <div class="container-fluid container-fluid-content" style="">
            <div class="h-100" [ngClass]="{'pixel' : (business$ | async) === 2}">
              <router-outlet></router-outlet>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #elseAuthenticate >
    <div id="app">
      <router-outlet></router-outlet>
    </div>
  </ng-template>
</div>
<ng-template #elseStyleConnected >
  <div class="container-fluid p-0">
    <router-outlet></router-outlet>
  </div>
</ng-template>

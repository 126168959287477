import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {Logout} from "./shared/models/Auth";
import {Actions, ofActionDispatched, Store} from "@ngxs/store";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {AuthState} from "./state/auth.state";
import {Title} from "@angular/platform-browser";
import {filter, Subject, takeUntil} from "rxjs";
import {first, map} from "rxjs/operators";
import {CheckForUpdateService} from "./check-for-update.service";
import {PromptUpdateService} from "./prompt-update.service";
import {GetSubscriber} from "./shared/models/Subscriber";
import {EProfileStore} from "./shared/data-access/e-profile.store";
import {TemplatesStore} from "./shared/data-access/templates.store";
import {SubscriberState} from "./state/subscriber-state.service";
import * as Sentry from "@sentry/angular-ivy";
import {environment} from "../environments/environment";
import {GlobalStore} from "./shared/data-access/global.store";
import {Store as StoreNgrx} from "@ngrx/store";
import * as EProfileActions from "./core/actions/eProfile.actions";
import * as EProfileSelectors from "./core/selectors/eProfile.selectors";
import * as SubscriptionSelectors from "./core/selectors/subscription.selectors";
import {SubscriptionModel} from "./core/models/subscription.model";

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [AuthState, EProfileStore, TemplatesStore, GlobalStore],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {

  isAuthenticate = false;
  isError = false;
  title = '';
  styleConnected: boolean | null = true

  subscriber: SubscriberState|null = null

  sticky: boolean = false

  subscription$ = this.storeNgrx.select(SubscriptionSelectors.selectCurrent)
  business$ = this.storeNgrx.select(EProfileSelectors.selectBusiness)

  ngUnsubscribe = new Subject<void>();

  @ViewChild('globalSticky')
  globalSticky: ElementRef

  @HostListener('window:scroll', ['$event'])
  onScroll(event: any) {
    if (window.scrollY >= 40) {
      let myElement = document.querySelector( '#nav-sticky') as HTMLElement;
      if (myElement && myElement.classList.contains('is-sticky')) {
        this.sticky = true
        this.ref.markForCheck()
      }
    } else {
      this.sticky = false
      this.ref.markForCheck()
    }
  }

  constructor(private actions: Actions, private router: Router, private store: Store,
              private activatedRoute: ActivatedRoute, private titleService: Title,
              private checkUpdate: CheckForUpdateService, private promptUpdateService: PromptUpdateService,
              private ref: ChangeDetectorRef, private storeNgrx: StoreNgrx) {

    this.store.select(AuthState.isAuthenticated).pipe(takeUntil(this.ngUnsubscribe)).subscribe(isAuthenticate => {
      this.isAuthenticate = isAuthenticate
      if (isAuthenticate) {
        this.business$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((business) => {
          if (business == 2) {
            localStorage.setItem('business', '2')
          } else {
            localStorage.setItem('business', '1')
          }
        })

        this.store.dispatch(new GetSubscriber())
      }
    })

    this.store.select(SubscriberState).pipe(first(val => val.email !== null), takeUntil(this.ngUnsubscribe)).subscribe((subscriber) => {
      // let auth = this.store.selectSnapshot(AuthState.isAuthenticated)
      //
      // console.log('subscriber', subscriber, auth)
      // this.subscriber = subscriber

      // if (subscriber && subscriber.profile_type === 'E') {
      //   console.log('EProfileActions.loadMe')
      //   this.storeNgrx.dispatch(EProfileActions.loadMe())
      // }

      // Sentry.setUser({ email: subscriber.email, profile_type: subscriber.profile_type});
      this.ref.markForCheck()

      if (subscriber && subscriber.status === 2) {
        this.router.navigate(['/invalidated'], {replaceUrl: true})
      }
    })

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map((data) => {
        if (this.router.url === '/404' || this.router.url === '/500' || this.router.url === '/403') {
          // this.isError = true
        }
        let child = this.activatedRoute.firstChild;
        while (child) {
          if (child.firstChild) {
            child = child.firstChild;
          } else if (child.snapshot.data) {
            return child.snapshot.data;
          } else {
            return null;
          }
        }
        return null;
      })
    ).pipe(takeUntil(this.ngUnsubscribe)).subscribe( (data: any) => {
      if (this.globalSticky) {
        this.globalSticky.nativeElement.innerHTML = ''
        this.ref.markForCheck()
      }
      if (data) {
        if (environment.production) {
          gtag('config', 'G-83LFHLE2VS', {
              'page_path': data.urlAfterRedirects
            }
          );
        }

        this.title = data.title
        this.styleConnected = typeof data.styleConnected === 'undefined' ? true : data.styleConnected
        if (data.title && data.title.length) {
          this.titleService.setTitle(data.title + ' - Fifty Talents');
        } else {
          this.titleService.setTitle('Fifty Talents');
        }
        if (data.observable$) {
          data.observable$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: any) => {
            if (data) {
              if (data.subscriber_id) {
                this.title = this.title + ' - #' + data.subscriber_id
              } else if (data.id) {
                this.title = this.title + ' - #' + data.id
              }
            }
          })
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngOnInit(): void {
    this.actions.pipe(ofActionDispatched(Logout)).pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.router.navigate(['/login'], {replaceUrl: true});
    })
  }

  ngAfterViewInit() {
  }

  willExpire(subscription: SubscriptionModel): boolean {
    if (subscription && subscription.schedule && subscription.schedule.current_phase) {
      let date1: any = new Date(subscription.schedule.current_phase.end_date);
      let date2: any = new Date();
      return Math.floor((date1 - date2) / (1000 * 60 * 60 * 24)) <= 15;
    }
    return false
  }
}

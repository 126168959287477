export const environment = {
  production: false,
  NAME: 'preprod',

  API_URL: 'https://preprod-api.fiftytalents.com/app',
  APP_URL: 'https://preprod-app.fiftytalents.com/',
  PUBLIC_URL: 'https://preprod.fiftytalents.com/',
  DATA_URL: 'https://preprod-api.fiftytalents.com/data/',
  API_OAUTH_URL: 'https://preprod-api.fiftytalents.com/oauth/token',
  OAUTH_CLIENT: '28',
  OAUTH_SECRET: 'LPxhee2PuzMywEkGogl2QoBPk6YmqVgztgcNIutd',

  PLAN_3: 'price_1OyG0wApkQDLUd1wdzZgw9fB',
  PLAN_6: 'price_1Oyd1fApkQDLUd1wm2U8BXql'
};

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {
  faCircleXmark,
  faGear,
  faMagnifyingGlass,
  faPen,
  faClockRotateLeft,
  faUser,
  faUsersViewfinder
} from "@fortawesome/pro-light-svg-icons";
import {faCaretDown, faCaretUp, faChevronDown} from "@fortawesome/pro-solid-svg-icons";
import {ActivatedRoute, Router} from "@angular/router";
import {Store as StoreNgrx} from "@ngrx/store";
import {AbstractControl, FormControl, FormGroup} from "@angular/forms";
import {Subject, take, takeUntil} from "rxjs";
import * as CryptoJS from "crypto-js";
import {map} from "rxjs/operators";
import {SkillModel} from "../../../core/models/skill.model";
import * as SearchActions from "../../../core/actions/search.actions";
import * as SubscriberSearchActions from "../../../core/actions/subscriberSearch.actions";
import * as CommonActions from "../../../core/actions/common.actions";
import * as EProfileActions from "../../../core/actions/eProfile.actions";
import {Actions, ofType} from "@ngrx/effects";
import * as SearchSelectors from "../../../core/selectors/search.selectors";
import * as SubscriberSearchSelectors from "../../../core/selectors/subscriberSearch.selectors";
import * as CommonSelectors from "../../../core/selectors/common.selectors";
import * as EProfileSelectors from "../../../core/selectors/eProfile.selectors";
import {ModalSearchComponent} from "../modal-search/modal-search.component";
import {SubscriberSearchModel} from "../../../core/models/subscriberSearch.model";
import {JobModel} from "../../../core/models/job.model";

@Component({
  selector: 'app-search-menu',
  templateUrl: './search-menu.component.html',
  styleUrls: ['./search-menu.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchMenuComponent implements OnInit, OnDestroy, AfterViewInit {

  fa = {faUser, faClockRotateLeft, faGear, faCaretDown, faCaretUp, faCircleXmark, faMagnifyingGlass, faPen, faChevronDown, faUsersViewfinder}

  @Input()
  offcanvas: boolean = false

  @ViewChild('modalSearchComponent')
  modalSearchComponent: ModalSearchComponent

  quota$ = this.storeNgrx.select(EProfileSelectors.selectQuota)
  eProfile$ = this.storeNgrx.select(EProfileSelectors.selectMe)
  skills$ = this.storeNgrx.select(CommonSelectors.selectSkills)
  business$ = this.storeNgrx.select(EProfileSelectors.selectBusiness)
  meta$ = this.storeNgrx.select(SearchSelectors.selectMeta)
  searchInProgress$ = this.storeNgrx.select(SearchSelectors.selectSearchInProgress)
  filter$ = this.storeNgrx.select(SearchSelectors.selectFilter)
  sort$ = this.storeNgrx.select(SearchSelectors.selectSort)

  subscriberSearches$ = this.storeNgrx.select(SubscriberSearchSelectors.selectAll)
  currentSubscriberSearch$ = this.storeNgrx.select(SubscriberSearchSelectors.selectCurrent)

  form = new FormGroup({
    content: new FormControl<string[] | null>([], {nonNullable: true}),
    jobs: new FormControl<JobModel[]>([], {nonNullable: true}),
    skills: new FormControl<SkillModel[]>([], {nonNullable: true}),
    experience_min: new FormControl<number | null>(0, {nonNullable: true}),
    experience_max: new FormControl<number | null>(8, {nonNullable: true}),
    remote: new FormControl<any[] | null>([], {nonNullable: true}),
    remote_min: new FormControl<number | null>(1, {nonNullable: true}),
    remote_max: new FormControl<number | null>(2, {nonNullable: true}),
    salary_min: new FormControl<number | null>(null, {nonNullable: true}),
    salary_max: new FormControl<number | null>(null, {nonNullable: true}),
    level: new FormControl<string | null>(null, {nonNullable: true}),
    zip_code: new FormControl<string | null>(null, {nonNullable: true}),
    city: new FormControl<string | null>(null, {nonNullable: true}),
    perimeter: new FormControl<number | null>(10, {nonNullable: true}),
    country: new FormControl<string | null>(null, {nonNullable: true}),
    school: new FormControl<string | null>(null)
  })

  formSearch = new FormGroup({
    subscriberSearchId: new FormControl<number|null>(null)
  })

  lastSearch: string | null = null
  lastSearchExecution: Date | null = null

  allSkills: SkillModel[] = []
  skillsItems: any[] = []
  searchInProgress: boolean = false

  nbItems = 50;
  currentPage = 1;
  currentFilter: string = 'all'
  currentSort = {order: 'desc', target: 'new'}

  business: number

  ngUnsubscribe = new Subject<void>();
  ngUnsubscribeSearch = new Subject<void>();

  constructor(private storeNgrx: StoreNgrx, private activatedRoute: ActivatedRoute, private ref: ChangeDetectorRef,
              private router: Router, private actions$: Actions) {
    this.storeNgrx.dispatch(CommonActions.loadSkills())

    this.searchInProgress$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((searchInProgress) => {
      this.searchInProgress = searchInProgress
    })

    this.filter$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((filter) => {
      if (JSON.stringify(this.currentFilter) !== JSON.stringify(filter)) {
        this.currentFilter = filter
        this.submit(true)
      }
    })

    this.sort$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((sort) => {
      if (JSON.stringify(this.currentSort) !== JSON.stringify(sort)) {
        this.currentSort = sort
        this.submit(true)
      }
    })

    this.actions$.pipe(
      ofType(SearchActions.loadSearchNextPage),
      map((action: any) => action.data),
      takeUntil(this.ngUnsubscribe)
    ).subscribe(() => {
      this.meta$.pipe(take(1)).subscribe((meta) => {
        if (!this.searchInProgress && meta.last_page > this.currentPage) {
          this.currentPage += 1
          this.submit(false)
        }
      })
    })

    this.currentSubscriberSearch$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((subscriberSearch) => {
      if (subscriberSearch && subscriberSearch.id) {
        this.formSearch.setValue({
          subscriberSearchId: Number(subscriberSearch.id)
        })
      }
    })

    this.business$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((business) => {
      this.business = business
    })
  }

  ngOnInit() {
    this.skills$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((skills) => {
      this.allSkills = [...skills]
      this.filterSkills([...skills])
    })

    if (this.business === 1) {
      this.storeNgrx.dispatch(SubscriberSearchActions.loadSearches())
      // this.storeNgrx.dispatch(SearchActions.resetSearch())

      this.activatedRoute.queryParams.pipe(take(1)).subscribe((params: any) => {
        if (params['query']) {
          const decrypted = CryptoJS.AES.decrypt(params['query'], 'encrypt');

          const data = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8))
          this.form.patchValue(data.search)
          // this.formSkills.patchValue(data.search)
          this.form.markAsTouched()
          // this.formSkills.markAsTouched()
          this.ref.markForCheck()
        } else {

          // //default request
          // this.form.markAsTouched()
          // this.formSkills.markAsTouched()
          this.submit()
        }
      })
    } else if (this.business === 2) {
      this.actions$.pipe(
        ofType(SubscriberSearchActions.loadSearchesSuccess),
        map((action: any) => action.data),
        takeUntil(this.ngUnsubscribe)
      ).subscribe(() => {
        if (this.modalSearchComponent) {
          this.modalSearchComponent.hide()
        }
      })
    }
    this.lastSearchExecution = new Date()
  }

  ngAfterViewInit(): void {
    this.form.controls.skills.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.filterSkills([...this.allSkills])
    })

    if (this.business === 1) {
      this.activatedRoute.queryParams.pipe(takeUntil(this.ngUnsubscribeSearch)).subscribe((params: any) => {
        if (this.router.url.indexOf('/search') !== -1 && this.router.url.indexOf('/search/') === -1) {

          if (params['query']) {
            const decrypted = CryptoJS.AES.decrypt(params['query'], 'encrypt');

            const data = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8))

            this.launchSearch(data)
            this.ref.markForCheck()
          } else {
            //reset
            this.form.reset()
            // this.formSkills.reset()
            this.form.controls.remote.patchValue([])
            // this.formSkills.markAsUntouched()
            this.form.markAsUntouched()
            this.submit()
          }

        } else {
          this.ngUnsubscribeSearch.next();
          this.ngUnsubscribeSearch.complete();
        }
      })

      this.form.valueChanges.pipe(takeUntil(this.ngUnsubscribeSearch)).subscribe((data) => {
        this.form.markAsTouched()
        this.ref.markForCheck()
        this.submit()
      })
    } else if (this.business === 2) {
      this.ngUnsubscribeSearch.next();
      this.ngUnsubscribeSearch.complete();
    }
  }

  submit(newSearch = true) {
    if (newSearch) {
      // this.storeNgrx.dispatch(SearchActions.resetSearch())
      this.currentPage = 1
    }

    let data = this.form.value
    // let skillsValue = this.formSkills.value
    // data.skills = skillsValue.skills?.concat(skillsValue.skillsAdd)

    data.skills = this.form.controls.skills.value
    let newData = {
      search: data,
      nb_items: this.nbItems,
      page: this.currentPage,
      filter: this.currentFilter,
      sort: this.currentSort
    }

    this.replaceUrl(newData)
  }

  replaceUrl(data: any) {

    // if (this.form.touched/* || this.formSkills.touched*/) {
      const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), 'encrypt');
      this.router.navigate(['/search'], {queryParams: {query: encrypted}})
    // }
  }

  launchSearch = (newData: any) => {
    this.storeNgrx.dispatch(SearchActions.updateSearchSortAndFilter({filter: newData.filter, sort: newData.sort}))

    const currentSearch = JSON.stringify(newData)
    if (currentSearch !== this.lastSearch) {
      this.lastSearchExecution = new Date()

      this.storeNgrx.dispatch(SearchActions.loadSearch({body: newData}))
    }
    this.lastSearch = currentSearch
  }

  reset() {
    this.router.navigate(['/search'], {replaceUrl: true})
    this.storeNgrx.dispatch(SubscriberSearchActions.setCurrentSubscriberSearch({subscriberSearch: null}))
    this.formSearch.reset()
  }

  filterSkills(filterSkills: any[]) {

    let skills = this.form.get('skills')?.value

    if (skills) {
      skills.forEach((skill: any) => {
        let skillFound = filterSkills.findIndex((skillIndex: any) => skillIndex.title === skill.title)
        if (skillFound !== -1) {
          filterSkills.splice(skillFound, 1)
        }
      })
    }

    this.skillsItems = [...filterSkills]
    this.ref.markForCheck()
  }

  changeBusiness(business: number) {
    this.storeNgrx.dispatch(EProfileActions.updateBusinessSelected({business}))
  }

  get salaryMin(): AbstractControl {
    return this.form.get('salary_min')!;
  }

  get salaryMax(): AbstractControl {
    return this.form.get('salary_max')!;
  }

  get level(): AbstractControl {
    return this.form.get('level')!;
  }

  resetExperiences() {
    this.form.patchValue({experience_min: 0, experience_max: 8})
  }

  resetSalary() {
    this.salaryMin.reset()
    this.salaryMax.reset()
  }

  resetLevel() {
    this.level.reset()
  }

  saveSearch(subscriberSearchSelected: SubscriberSearchModel | null = null) {
    let subscriberSearch: SubscriberSearchModel | null = null

    subscriberSearch = {
      id: null,
      name: null,
      content: this.form.get('content')?.value ?? [],
      salary_min: this.salaryMin.value,
      salary_max: this.salaryMax.value,
      experience_min: this.form.get('experience_min')?.value ?? null,
      experience_max: this.form.get('experience_max')?.value ?? null,
      remote: this.form.get('remote')?.value ?? [],
      remote_min: this.form.get('remote_min')?.value ?? null,
      remote_max: this.form.get('remote_max')?.value ?? null,
      city: this.form.get('city')?.value ?? null,
      city_perimeter: this.form.get('city_perimeter')?.value ?? null,
      level: this.form.get('level')?.value ?? null,
      country: this.form.get('country')?.value ?? null,
      school: this.form.get('school')?.value ?? null,
      zip_code: this.form.get('zip_code')?.value ?? null,
      jobs: this.form.get('jobs')?.value ?? [],
      skills: this.form.get('skills')?.value ?? [],
    }

    if (subscriberSearchSelected) {
      subscriberSearch.id = subscriberSearchSelected.id
      subscriberSearch.name = subscriberSearchSelected.name

      this.modalSearchComponent.show(subscriberSearch)
    } else {
      this.currentSubscriberSearch$.pipe(take(1)).subscribe((data) => {
        if (subscriberSearch) {

          if (data) {
            subscriberSearch.id = data.id
            subscriberSearch.name = data.name
          }
          this.modalSearchComponent.show(subscriberSearch)
        }
        // this.storeNgrx.dispatch(SubscriberSearchActions.setCurrentSubscriberSearch({subscriberSearch}))
      })
    }
  }

  selectSearch(subscriberSearch: SubscriberSearchModel) {

    if (subscriberSearch) {
      this.form.patchValue({
        content: subscriberSearch.content,
        experience_min: subscriberSearch.experience_min,
        experience_max: subscriberSearch.experience_max,
        salary_min: subscriberSearch.salary_min,
        salary_max: subscriberSearch.salary_max,
        remote: subscriberSearch.remote,
        remote_min: subscriberSearch.remote_min,
        remote_max: subscriberSearch.remote_max,
        school: subscriberSearch.school,
        city: subscriberSearch.city,
        perimeter: subscriberSearch.city_perimeter,
        level: subscriberSearch.level,
        country: subscriberSearch.country,
        zip_code: subscriberSearch.zip_code,
        skills: subscriberSearch.skills,
        jobs: subscriberSearch.jobs
      })

      this.storeNgrx.dispatch(SubscriberSearchActions.setCurrentSubscriberSearch({subscriberSearch: subscriberSearch}))
    }
  }

  formIsNotEmpty(): boolean {
    return ((this.form.controls.content.value !== null && this.form.controls.content.value?.length !== 0)
      || this.form.controls.skills.value?.length !== 0 || this.form.controls.jobs.value?.length !== 0
      || !(this.form.controls.experience_min.value === 0 && this.form.controls.experience_max.value === 8)
      || (!(this.form.controls.salary_min.value === 0 || this.form.controls.salary_min.value === null)
        && !(this.form.controls.salary_max.value === 0 || this.form.controls.salary_max.value === null))
      || this.form.controls.remote.value?.length !== 0
      || (this.form.controls.city.value !== null && this.form.controls.city.value !== '')
      || (this.form.controls.school.value !== null && this.form.controls.school.value !== '')
      || (this.form.controls.level.value != null && this.form.controls.level.value != ''));
  }

  ngOnDestroy(): void {
    this.storeNgrx.dispatch(SubscriberSearchActions.setCurrentSubscriberSearch({subscriberSearch: null}))

    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();

    this.ngUnsubscribeSearch.next();
    this.ngUnsubscribeSearch.complete();
  }
}

export interface SubscriberStateModel {
  id: string | null,
  first_name: string | null,
  last_name: string | null,
  email: string | null
  status: number | null,
  approval: boolean | null,
  availability: null,
  profile_type: null,
  avatar: null,
  phone: null,
}

export class GetSubscriber {
  static readonly type = '[Subscriber] get';
  constructor() {}
}

export class Logout {
  static readonly type = '[Subscriber] Logout';
}

